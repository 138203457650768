import React from 'react';
import SEO from 'components/seo';

// Component
import TextImageBlock from 'components/organisms/Blocks/TextImageBlock/TextImageBlock';
import Hero from 'components/molecules/Hero/Hero';

// Data
import BedrijfsVloerenData from 'data/BedrijfsVloerenPage.json';

const BedrijfsvloerenPage = () => (
  <>
    <SEO title="Bedrijfsvloeren" />
    <TextImageBlock
      data={BedrijfsVloerenData.intro}
    />
    <Hero
      modifier="inPage"
      {...BedrijfsVloerenData.inPageHero}
    />
  </>
);
export default BedrijfsvloerenPage;
